import {atom, useAtom} from "jotai/index";
import {atomGlobalExperiment, atomGlobalReferenceSpectrum, atomGlobalSpectrum} from "../../../Data/Atoms";
import {useAutoGet} from "../../Global/Message";
import {urlMassWiki} from "../../../Config/MassWikiUrl";
import {useEffect, useState} from "react";
import {zip} from "../../../Library/Tools";

export const atomReferenceLibrary = atom([]);
export const atomReferenceLibraryTrigger = atom([]);
export const atomReferenceLibrarySelectedTrigger = atom("");
export const atomSearchType = atom("identity_search");

export default () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateSearchType,] = useAtom(atomSearchType);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // For atomReferenceLibrary
    const [, setReferenceLibrary] = useAtom(atomReferenceLibrary);
    const [, setReferenceLibraryTrigger] = useAtom(atomReferenceLibraryTrigger);
    useEffect(() => {
        if (stateGlobalSpectrum.analysis) {
            const librarySearchScoreList = ((stateGlobalSpectrum.analysis || {}).reference_library || {})[stateSearchType] || [];
            setReferenceLibraryTrigger(librarySearchScoreList.map((d) => d[0]));
        }
    }, [stateGlobalSpectrum, stateSearchType])

    // Get Library data
    const [, messageGetLibraryMultiData] =
        useAutoGet(
            atomReferenceLibraryTrigger,
            (stateTrigger) => stateTrigger.length > 0,
            urlMassWiki.referenceLibrary.getManyByWikiId,
            () => setReferenceLibrary([]),
            (response) => {
                const searchResult = ((stateGlobalSpectrum.analysis || {}).reference_library || {})[stateSearchType] || [];
                const annotationData = zip(response, searchResult);
                const globalSpectrumPrecursorMz = (stateGlobalSpectrum.spectrum || {}).precursor_mz;
                const searchResultData = annotationData.map((data, index) => {
                    const info = data[0];
                    const score = data[1];
                    info.key = info.wiki_id;
                    if (info.db === "MassBank.us") {
                        info.link = `https://mona.fiehnlab.ucdavis.edu/spectra/display/${info.id}`;
                    } else if (info.db === "GNPS") {
                        info.link = `https://gnps.ucsd.edu/ProteoSAFe/gnpslibraryspectrum.jsp?SpectrumID=${info.id}`;
                    }
                    info.score = score[1];
                    info.delta_mz = info.precursor_mz - globalSpectrumPrecursorMz
                    return info;
                })
                setReferenceLibrary(searchResultData)
            },
            "urlMassWiki.library.getManyByWikiId",
            "Loading library search result..."
        );

    // For atomReferenceLibrarySelectedTrigger
    const [, setGlobalReferenceSpectrumData] = useAtom(atomGlobalReferenceSpectrum);
    const [, messageGetLibrarySingleData] =
        useAutoGet(
            atomReferenceLibrarySelectedTrigger,
            (stateTrigger) => !!stateTrigger,
            urlMassWiki.referenceLibrary.getOneByWikiId,
            () => setGlobalReferenceSpectrumData({}),
            (data) => setGlobalReferenceSpectrumData(data),
            "urlMassWiki.library.getOneByWikiId",
            "Loading selected Library data..."
        );


    return <>
        {messageGetLibraryMultiData}
        {messageGetLibrarySingleData}
    </>
}