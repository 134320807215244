import {Col, Row, Space, Timeline, Card, Avatar, Empty, message, notification,} from 'antd';
import React, {useState, useContext, useEffect, useRef} from 'react';
import {atom, useAtom} from "jotai";
import {usePlotMolecule} from "../../../Library/PlotMolecule";
import {atomAnnotationSearchSelected} from "../AnnotationLibrary/AnnotationSearchResultData";
import {UserOutlined} from "@ant-design/icons";

const UserAnnotationCard = (props) => {
    const element = props.element;
    const annotationMol = element.mol || {};
    const annotationAnnotation = element.annotation || {};
    const annotationUser = element.user || {};
    const time = props.time;
    // console.log(time, element)
    const [contextPlotMolecule, setPlotMoleculeSmiles] = usePlotMolecule({width: 150, height: 120});
    const refPlot = useRef(null)
    useEffect(() => {
        if (annotationMol.smiles && refPlot.current) {
            setPlotMoleculeSmiles(annotationMol.smiles)
        }
    }, [annotationMol.smiles])

    // console.log(refPlot)
    return <div style={{padding: "10px"}}>
        <Card style={{padding: "0px"}}>
            <Row justify="center" align="middle">
                <Col span={3}>
                    <Row justify="center" align="middle">
                        <Col>
                            <Space direction="vertical" style={{display: 'flex'}} align="center">
                                <Avatar
                                    size={{xs: 12, sm: 16, md: 20, lg: 32, xl: 40, xxl: 50}}
                                    style={{backgroundColor: '#f56a00', verticalAlign: 'middle'}}
                                    icon={<UserOutlined/>}>
                                    {annotationUser.name}
                                </Avatar>
                                <h3>{annotationUser.name}</h3>
                                <p>{(new Date(time + "Z")).toLocaleString().split(",")[0]}</p>
                            </Space>
                        </Col>
                    </Row>
                </Col>
                <Col span={12}>
                    <p>Molecular name: {annotationMol.name}</p>
                    <p>SMILES: {annotationMol.smiles}</p>
                    <p>Adduct: {annotationMol.adduct}</p>
                    <p>Comment: {annotationAnnotation.comments}</p>
                </Col>
                <Col span={9}>
                    <Row justify="center" align="middle" ref={refPlot}>
                        {contextPlotMolecule}
                    </Row>
                </Col>
            </Row>
        </Card>
    </div>
}

export default (props) => {
    const [stateTimelineData, setTimelineData] = useState([])
    const [stateGlobalAnnotationData,] = useAtom(atomAnnotationSearchSelected);

    useEffect(() => {
        console.log(stateGlobalAnnotationData)
        if (stateGlobalAnnotationData.length === 0) {
            setTimelineData([])
        } else {
            let annotationTableData = stateGlobalAnnotationData.map((element, index) => {
                const annotationData = element.data
                const annoTime = new Date(annotationData.time_stamp * 1000)
                return {
                    children: <UserAnnotationCard element={annotationData} time={annoTime}/>,
                    key: element.id,
                    time: annoTime,
                }
            });
            annotationTableData.sort((a, b) => {
                return b.time - a.time
            })
            // console.log(annotationTableData)
            setTimelineData(annotationTableData)
        }
    }, [stateGlobalAnnotationData]);

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            ellipsis: true,
            width: 150
        }, {
            title: 'IdentifiedBy',
            dataIndex: 'identified_by',
            key: 'identifiedBy',
            ellipsis: true,
            width: 120
        }, {
            title: 'Comment',
            dataIndex: 'comment',
            key: 'comment',
            ellipsis: true,
            width: 200
        }]
    return <>
        <div>{
            stateTimelineData.length > 0 ?
                <Timeline items={stateTimelineData}/> : <Empty description={"No user annotations."}>
                </Empty>
        }
        </div>
    </>
}