import {Button, Checkbox, Col, Row, Tabs, Tag} from 'antd';
import React, {useEffect, useState} from "react";
import {atom, useAtom} from "jotai";
import VirtualTable from "../../../Library/VirtualTable";
import {atomGlobalExperiment, atomGlobalSpectrum} from "../../../Data/Atoms";
import {atomGlobalReferenceSpectrum} from "../../../Data/Atoms";
import {
    atomExperimentalLibrary,
    atomExperimentalLibraryTrigger,
    atomExperimentalLibrarySelectedTrigger
} from "./ExperimentalLibraryResultData";

import ExperimentalLibraryResultData from "./ExperimentalLibraryResultData";

const column = [
    {
        title: 'Study ID', dataIndex: 'study_id', key: 'study_id', ellipsis: false, width: 80,
        sorter: (a, b) => a.study_id.localeCompare(b.study_id),
    }, {
        title: 'File name', dataIndex: 'file_name', key: 'file_name', ellipsis: true, width: 280,
        sorter: (a, b) => a.file_name.localeCompare(b.file_name),
    }, {
        title: "Scan",
        dataIndex: "scan",
        key: "scan",
        sorter: (a, b) => (a["scan"] ? a["scan"] : 0) - (b["scan"] ? b["scan"] : 0),
        width: 60,
    }, {
        title: "Similar spectra number",
        dataIndex: "similar_count",
        key: "similar_count",
        sorter: (a, b) => (a["similar_count"] ? a["similar_count"] : 0) - (b["similar_count"] ? b["similar_count"] : 0),
        width: 120,
        defaultSortOrder: 'descend',
    }
]

export default () => {
    const [stateGlobalRunData,] = useAtom(atomGlobalExperiment);
    const [stateGlobalSpectrum,] = useAtom(atomGlobalSpectrum);
    const [stateExperimentalLibrary,] = useAtom(atomExperimentalLibrary);
    const [, setExperimentalLibraryTrigger] = useAtom(atomExperimentalLibraryTrigger);
    const [, setExperimentalLibrarySelectedTrigger] = useAtom(atomExperimentalLibrarySelectedTrigger);

    const globalMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;

    // useEffect(() => {
    //     console.log("atomExperimentalLibrary", stateExperimentalLibrary);
    // }, [stateExperimentalLibrary]);

    ////////////////////////////////////////////////////////////////////////////////
    const [stateSearchResults, setSearchResults] = useState({});
    // Dealing with different search types
    useEffect(() => {
        const search_result = (stateGlobalSpectrum.analysis || {}).experimental_library;
        if (search_result && search_result.similar_spectra) {
            const chromMethodId = ((stateGlobalRunData.metadata || {}).method || {}).library_id;
            setExperimentalLibraryTrigger({method_id: chromMethodId, id_list: search_result.similar_spectra});
        }
    }, [stateGlobalSpectrum.analysis, stateGlobalRunData.metadata]);

    ////////////////////////////////////////////////////////////////////////////////
    const [stateGlobalReferenceSpectrumData,] = useAtom(atomGlobalReferenceSpectrum);

    return <>
        <ExperimentalLibraryResultData/>
        <Row>
            <Col span={24}>
                <VirtualTable
                    vid={"experimental-library-result-table"}
                    onRow={record => {
                        return {
                            onClick: event => {
                                console.log(globalMethodId, record)
                                setExperimentalLibrarySelectedTrigger({
                                    method_id: globalMethodId,
                                    study_id: record.study_id,
                                    file_name: record.file_name,
                                    scan: record.uniq_scan,
                                })
                            }
                        }
                    }}
                    rowClassName={record => {
                        return (stateGlobalReferenceSpectrumData.scan === `${record.uniq_scan}`) ? "row-active" : "";
                    }}
                    height={200}
                    size={'small'}
                    columns={column} dataSource={stateExperimentalLibrary}/>
            </Col>
        </Row>
    </>;
};
